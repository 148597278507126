.EmbeddedVideo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.imgContainer > img {
    object-fit: cover;
    width: 100%;
    height: 350px;
}

.imgContainer {
    background-color: red;
}