.SearchSection {
    display: flex;
    flex-direction: column;
}

.SearchBar {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin-left: 2.5%;
}

.SearchBarField {
    flex:1;
    margin: 0;
}

.SearchResults {
    width: 95%;
    margin-left: 2.5%;
}

.SearchResultsButton {
    width: 95%;
    margin-left: 2.5%;
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

.SearchHeaderButtonRow {
    display: flex;
    flex-direction: row-reverse;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 30px;
}

.SearchHeaderButton {
    margin-right: 0;
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

.ImportMenu {
    display: flex;
    flex-direction: column;
}

.ImportMenuButton {
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

.QuizletImportHelpButtonRow {
    display: flex;
    flex-direction: row-reverse;
}

.QuizletImportSubmitRow {
    display: flex;
    flex-direction: row;
}

.QuizletImportSubmitButton {
    margin-left: 0;
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

.QuizletImportHelpButton {
    margin-right: 0;
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

.QuizletImportSection {
    padding-top: 0;
}

.QuizletImportInstructionsList {
    display: flex;
    flex-direction: column;
}

.AnkiDropZone {
    border: 1px black dotted;
    height: 50px;
}

.SpinnerColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40vh;
}

.SpinnerColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
}
