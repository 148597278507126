.TermsContainer {
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}

h1 {
    font-size: x-large;
    font-weight: bold;
}

h2 {
    font-size: medium;
    font-weight: bold;
}

h3 {
    font-size: medium;
    font-weight: normal;
}