.Login {
  padding: 20px 0;
}

.Login {
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
  max-width: 320px;
  width: 100%;
  background-color: #0094A2;
  color: white;
}

.UITextField form-control {
  border: none;
  background-color: #0094A2;
}

.GoogleLoginButton {
  width: 100%;
}