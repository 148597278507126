.CardDisplay {
    height: 30vh;
    border-radius: 5px;
    padding: 10px;
    background-color: #E5E5E5;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.FlipButton {
    background-color: #61cbf2;
    border: 1px black solid;
    border-radius: 5px;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
}

.ButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PlayPauseButton {
    background-color: #61cbf2;
    border: 1px black solid;
    border-radius: 5px;
    max-width: 100%;
    width: 200px;
}

.AnswerPrompt {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.AnswerButtonRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.AnswerButton {
    flex: 1;
    background-color: #61cbf2;
    border: 1px black solid;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
}