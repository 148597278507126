.StudyVoiceButton {
    width: 100%;
    background-color: #61cbf2 !important;
    border: solid black 1px;
    color: black;
}

.SpeakButton {
    border: 1px solid black;
    background-color: #fabc2a !important;
    color: black;
}

.StudyVoiceLinks {
    display: flex;
    flex-direction: row;
}