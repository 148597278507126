.CreateCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.CreateCardLanguageBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px gray dotted;
}

.LanguageDropdown {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.CreateCardBody {
    display: flex;
    flex-direction: row;
}

.CreateCardBodyActionBar {
    display: flex;
    flex: 1;
}

.CreateCardBodyCenter {
    width: 100%;
    padding: 5%;
    padding-top: 0%;
}

.CardTextArea {
    height: 100%;
    background-color: #E5E5E5;
    border-radius: 5px;
    border: 0px;
    resize: none;
    margin: 2%;
    flex: 8;
}

.NavIcon {
    font-size: 64px;
    color: #61cbf2;
    cursor: pointer;
}

.Card {
    border-radius: 5px;
    border: 1px black solid;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
}

.CardActionBar {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CardQADivider {
    border: 1px black dotted;
}

.CreateCardBodyNav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 74px;
}

.CreateCardFooter {
    border-top: 1px black dotted;
    padding-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

.CreateCardFrame {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.FormGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 5;
    min-width: 300px;
    margin-right: 10px;
    margin-left: 10px;
}

.FormButton {
    height: 49px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
    margin-bottom: 20px;
    background-color: #61cbf2 !important;
    border: 1px black solid;
    color: black;
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
}

.CardCountDisplay {
    margin-left: 10px;
    min-width: 300px;
    flex: 5;
    text-align: left;
    margin-bottom: 10px;
}