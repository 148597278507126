.SignUpForm {
    display: flex;
    flex-direction: column;
}

.SignUpFormGroup {
    display: flex;
    flex-direction: column;
}

.SignUpFormButton {
    background-color: #61cbf2 !important;
    border: 1px black solid;
    color: black;
    width: 100%;
    margin-top: 20px;
}

.AuthenticationModalHeader {
    display: flex;
    flex-direction: column;
}