.TitleBar {
    display: flex;
    flex-direction: row-reverse;
}

.CreateButton {
    font-size: 32pt;
    color: black;
    background-color: #61cbf2 !important;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border: 1px black solid;
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    margin-left: 10px;
}

.HomeSectionHeader {
    color: #61cbf2;
    font-size: 24pt;
    border-bottom: #61cbf2 solid 1px;
}

.SetButton {
    color: #000000;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #61cbf2 !important;
}

.HomeSection {
    margin-top: 15px;
}

.CreateButtonText {
    font-size: 16pt;
}