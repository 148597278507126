.AccordionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 7vh;
    padding: 1vh;
    vertical-align: middle;
}

.AccordionHeader, div {
    vertical-align: middle;
}

.ActionBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ContinueButton {
    background-color: lime;
}

.DynamicVerticalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.PitchAccordion {
}

.PitchImage {
    max-height: 15vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
		width: 100%;
}

.Title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: calc(10px + .5vw + 1.5vh);
    margin-bottom: 2vh;
}

.card-body {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
}

.PitchButton {
    margin-top: 1vh;
    width: 100%;
    background-color: #61cbf2 !important;
    border-color: #000000 !important;
    color: #000000;
}

p {
    text-align: center;
}