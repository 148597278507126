.GoMobileContent {
    margin: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.GoMobileMessage {
    text-align: center;
}

.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MobileSection {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5%;
    padding: 5px;
    border: black 1px solid;
    border-radius: 5px;
}

.SignUpButton {
    background-color: #61cbf2 !important;
    border-color: #000000;
    color: #000;
}

.MobileSectionTitle {
    font-weight: 700;
    font-size: auto;
}

div.ios-store-button {
    margin-left: 12px;
}

div.ios-store-button > a {
    transform: translateY(-12px);
}

.app-store-container {
    justify-content: center;
    display: grid;
}

div.app-store-container > div {
    transform: translateX(35px);
    margin-top: 20px;
}

div.app-store-container > br {
    margin-bottom: 30px;
}